import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faOtter, faBrain, faComments, faFileLines, faListCheck, faBookOpen, faChevronRight, faTrashCan, faBoxArchive } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addMessage, newWritingProject, saveDocument, getWritingProjects, logout } from './functions/firebase';

import './App.css';
import './styles/examples.css';
import './styles/messaging.css';

import ChatPage from './pages/chatPage';
import KnowledgePage from './pages/knowledgePage';
import ToDoPage from './pages/toDoPage';
import WritingsPage from './pages/writingsPage';

const Core = (props) => {
  const MAX_TITLE_LENGTH = 40;

  library.add(faOtter, faBrain, faComments, faFileLines, faListCheck, faBookOpen, faChevronRight, faTrashCan, faBoxArchive);

  const [activeTab, setActiveTab] = useState('To Do'); // Default tab
  const [listOfWritingProjects, setListOfWritingProjects] = useState([]); // Default tab
  const [activeWritingProject, setActiveWritingProject] = useState(null); // Default tab
  const [showArchived, setShowArchived] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(true); // Add this state

  const changeTab = (tabName) => {
    setActiveTab(tabName);
  }

  const user = props.user;

  useEffect(() => {
    const getWPs = async () => {
      var list = await getWritingProjects(user.uid);
      setListOfWritingProjects(list);
      if(list.length > 0){
        setActiveWritingProject(list[0]);
      }
    }

    getWPs();
  },[]);

  const refreshListOfWritingProjects = async () => {
    var list = await getWritingProjects(user.uid);
    setListOfWritingProjects(list);
  }

  const changeActiveWritingProject = (writingProject) => {
    setActiveWritingProject(writingProject);
    setActiveTab('Write');
  }

  const initNewProject = async () => {
    const newWP = await newWritingProject(user.uid);
    var newList = [...listOfWritingProjects];
    newList.unshift(newWP);
    console.log(newList);
    console.log(newWP);
    setListOfWritingProjects(newList);
    setActiveWritingProject(newWP);
    setActiveTab('Write');
  }

  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <div className="App">
      <div className={`v-navbar ${isNavExpanded ? 'expanded' : 'minimized'}`}>
        <div className='logo'>
          Kai
          <span className="nav-toggle" onClick={toggleNav}>
            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
          </span>
        </div>
        <div className={`nav-tab ${activeTab === 'To Do' ? 'active' : ''}`} onClick={() => changeTab('To Do')}>
          <FontAwesomeIcon icon="fa-solid fa-list-check" />
          {isNavExpanded && <span>To Do</span>}
        </div>
        <div className={`nav-tab ${activeTab === 'Knowledge' ? 'active' : ''}`} onClick={() => changeTab('Knowledge')}>
          <FontAwesomeIcon icon="fa-solid fa-brain" />
          {isNavExpanded && <span>Knowledge</span>}
        </div>
        <div className={`nav-tab`} onClick={initNewProject}>
          <FontAwesomeIcon icon="fa-solid fa-comments" />
          {isNavExpanded && <span>New Writing</span>}
        </div>
        <div className='line-below'></div>
        
        {/* Active Writing Projects */}
        {listOfWritingProjects && listOfWritingProjects
          .filter(wp => !wp.completionStatus)
          .map((writingProject, index) => (
            <div 
              className={`nav-tab ${writingProject.id === activeWritingProject?.id && activeTab === "Write" ? 'active' : ''}`} 
              key={index} 
              onClick={() => changeActiveWritingProject(writingProject)}
            >
              {isNavExpanded ? 
                (writingProject.title.length > MAX_TITLE_LENGTH ? 
                  writingProject.title.substring(0, MAX_TITLE_LENGTH) + '...' : 
                  writingProject.title) : 
                `${index + 1}`}
            </div>
          ))
        }

        {/* Archive Section */}
        {listOfWritingProjects && listOfWritingProjects.some(wp => wp.completionStatus) && (
          <>
            <div className='line-below'></div>
            <div className={`nav-tab`} onClick={() => setShowArchived(!showArchived)}>
              <FontAwesomeIcon icon="fa-solid fa-box-archive" />
              {isNavExpanded && <span>{showArchived ? "Hide Archive" : "Show Archive"}</span>}
            </div>
            
            {showArchived && listOfWritingProjects
              .filter(wp => wp.completionStatus)
              .map((writingProject, index) => (
                <div 
                  className={`nav-tab archived ${writingProject.id === activeWritingProject?.id && activeTab === "Write" ? 'active' : ''}`} 
                  key={index} 
                  onClick={() => changeActiveWritingProject(writingProject)}
                >
                  {isNavExpanded ? 
                    (writingProject.title.length > MAX_TITLE_LENGTH ? 
                      writingProject.title.substring(0, MAX_TITLE_LENGTH) + '...' : 
                      writingProject.title) : 
                    `${index + 1}`}
                </div>
              ))
            }
          </>
        )}
      </div>  
     
      {activeTab === 'Write' && (
        // Content for "Write" tab
        <ChatPage
          user={props.user}
          activeWritingProject={activeWritingProject}
          refreshListOfWritingProjects={refreshListOfWritingProjects}
        />
      )}
      {activeTab === 'To Do' && (
        // Content for "Writings" tab
        <ToDoPage user={props.user} />
      )}
      {activeTab === 'Knowledge' && (
        // Content for "Knowledge" tab
        <KnowledgePage user={props.user} />
      )}
      {activeTab === 'Writing' && (
        // Content for "Writing" tab
        <WritingsPage user={props.user} listOfWritingProjects={listOfWritingProjects} />
      )}
            <button className='logout' onClick={logout}>Logout : {user.uid.substring(0,5)} v1.2.0</button>

    </div>
  );
}

export default Core;
