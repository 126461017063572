import React, { useState, useEffect, useRef } from 'react';
import Markdown from 'react-markdown'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCopy, faChevronCircleLeft, faChevronCircleRight, faPenToSquare, faPencil, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import InputDocument from '../components/inputDocument';
import WPTitle from '../components/wpTitle';

const DocumentArea = (props) => {

  library.add(faCopy, faChevronCircleLeft, faChevronCircleRight, faPenToSquare, faPencil, faCheck);

  const [listOfDocuments, setListOfDocuments] = useState([]); // Default tab  
  const [documentContent, setDocumentContent] = useState('');
  const [copied, setCopied] = React.useState(false);
  const [writingProject, setWritingProject] = useState(props.writingProject);
  const [userWritingDocument, setUserWritingDocument] = useState(props.userWritingDocument);
  const [messages, setMessages] = useState(props.messages); // Default tab
  const [WPcompletionStatue, setWPcompletionStatus] = useState(false);
  const [writingType, setWritingType] = useState(undefined);

  // If the messages change, figure out all the documents in the message history
  // set the list of documents to the list of documents in the message history
  // set the current document content to the latest document in the message history

  const user = props.user;

  useEffect(() => {

    var listOfDocumentsInMessageHistory = [];
    for(var i = 0; i < props.messages.length; i++) {
      var message = props.messages[i];
      if(message.role == 'assistant') {
        var messageContent = JSON.parse(message.content);
        if(messageContent['written-output'] != "") {
          listOfDocumentsInMessageHistory.push({'content': messageContent['written-output'], 'writingType': messageContent['writing-type']});
        }
      }
    }

    var mostRecentDocument = ""
    if(listOfDocumentsInMessageHistory.length > 0) {
      mostRecentDocument = listOfDocumentsInMessageHistory[listOfDocumentsInMessageHistory.length - 1];
    }

    setListOfDocuments(listOfDocumentsInMessageHistory);
    setDocumentContent(mostRecentDocument);
    setMessages(props.messages);
  }, [props.messages]); // Dependency array includes messages

  useEffect(() => {
    setUserWritingDocument(props.userWritingDocument);
  }, [props.userWritingDocument]);

  useEffect(() => {
    setWritingProject(props.writingProject);
    setWPcompletionStatus(props.writingProject.completionStatus);
  }, [props.writingProject]);

  const onCopy = React.useCallback(() => {
    setCopied(true);
  }, [])

  const markWPComplete = async (value) => {
    props.updateWPcompletion(value);
    setWPcompletionStatus(value);
  }

  return (
    <div className='cedric-center'>
      <div className='document-area'>
        <div className='writing-toolbar'>
          { userWritingDocument == true && messages.length < 3 ? 
            <div className='chatHeader' style={{ position: 'sticky', top: 0 }}>
              <WPTitle refreshListOfWritingProjects={props.refreshListOfWritingProjects} user={user} writingProject={writingProject} />
              <CopyToClipboard onCopy={onCopy} text={documentContent}>
                <span className='copy-btn'> <FontAwesomeIcon icon="fa-copy" /></span>
              </CopyToClipboard>
              <span onClick={props.toggleUserWritingDocument} className='copy-btn'><FontAwesomeIcon icon="fa-pencil" /></span>
            </div>
            :
            <h1>
              { WPcompletionStatue == true ?
                <button onClick={() => markWPComplete(false)} className='copy-btn done'><FontAwesomeIcon icon="fa-check" /></button>
                : 
                <button onClick={() => markWPComplete(true)} className='copy-btn'><FontAwesomeIcon icon="fa-check" /></button>
              }
              <CopyToClipboard onCopy={onCopy} text={documentContent.content}>
                <span className='copy-btn'> <FontAwesomeIcon icon="fa-copy" /></span>
              </CopyToClipboard>
              <button onClick={props.toggleUserWritingDocument} className='copy-btn'><FontAwesomeIcon icon="fa-pencil" /></button>
              { documentContent.writingType != undefined && documentContent.writingType != 'none' ? <div className='writing-type-bubble'>Writing Type: {documentContent.writingType}</div> : "" }
            </h1>
          }
          
          {/* { listOfDocuments.length > 0 ?
          <span>
            <p>Version {listOfDocuments.length}</p> */}
            {/* <div className='right-aligned'>
              <button><FontAwesomeIcon icon="fa-solid fa-circle-chevron-left" /></button>
              <button><FontAwesomeIcon icon="fa-solid fa-circle-chevron-right" /></button> 
            </div>  */}
          {/* </span>
          :  ""}       */}
        </div>  
        { userWritingDocument ?
          <InputDocument user={props.user} writingProject={props.writingProject} messages={props.messages} />
          :
          <Markdown className="markdown-block">{documentContent.content}</Markdown>
        }
      </div>
    </div>
  );
};

export default DocumentArea;
