import React, { useState, useEffect } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faToggleOn, faBrain } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { askGPT, askGPTJSON, compressChatHistoryIntoString, trySaveNewDocument, playSuccessChime } from './../functions/coreFunctions';
import prompts from './../prompts';

import WPTitle from '../components/wpTitle';
import Textbox from './../components/textbox';
import ChatConversation from './../components/chatConversation';
import DocumentArea from './../components/document';

import { addMessage, getMessages, getNote, updateWPcompletionStatus, getWritingTypes, getWritingTypeExamples } from './../functions/firebase';
import { getKnowledgeBase, updateKnowledgeGraphFromConversation } from '../functions/knowledge';

const ChatPage = (props) => {

  library.add(faToggleOn, faBrain);

  // Messages to be displayed in chat is an array of objects with the following structure:
  const [messages, setMessages] = useState([
    {"role": "system", "content": prompts.openingSetup}
  ]);
  const [innerMessages, setInnerMessages] = useState([]);
  const [showInnerMonologue, setShowInnerMonologue] = useState('none');
  const [writingProject, setWritingProject] = useState(props.activeWritingProject);
  // Thinking state is used to show the loading icon
  const [thinking, setThinking] = useState(false);
  // Sets whether the user is writing a document in the editor or not
  const [userWritingDocument, setUserWritingDocument] = useState(false);
  const [knowledgeBase, setKnowledgeBase] = useState(null);
  const [writingTypes, setWritingTypes] = useState(null);

  const user = props.user;

  useEffect(() => {
    
    const getMessagesFromDB = async () => {
      var messagesFromDB = await getMessages(user.uid, props.activeWritingProject.id);
      var noteData = await getNote(user.uid, props.activeWritingProject.id);
      var knowledgeBase = await getKnowledgeBase(user.uid);
      var writingTypes = await getWritingTypes(user.uid);
      setMessages(messagesFromDB);
      setKnowledgeBase(knowledgeBase);
      setWritingTypes(writingTypes);

      if(noteData != null) {
        setUserWritingDocument(true);
      }
    }
    
    if(props.activeWritingProject != null){
      setWritingProject(props.activeWritingProject);
      setUserWritingDocument(false);
      getMessagesFromDB();
    }

  },[props.activeWritingProject]);


  // This controls how the system should react to when a new message is added to the message history
  // by either the user or the assistant
  useEffect(() => {
    var newestMessage = messages[messages.length - 1];
    var latestMessages = JSON.parse(JSON.stringify(messages));
    // Removes the opening setup message from the latestMessages array
    latestMessages.shift()


    // If a user submits a message, then we want to ask GPT for a response
    if(newestMessage.role == 'user') {
      innerMonologue(latestMessages);      
    }
  }, [messages]);

  const innerMonologue = async (chatHistoryArray) => {
    var writingTypesSimple = writingTypes.map(({ createdAt, id, ...rest }) => rest)
    writingTypesSimple = JSON.stringify(writingTypesSimple);

    var chatHistoryString = compressChatHistoryIntoString(chatHistoryArray);

    console.log('starting inner monologue');

    var IMopeningSetup =
    `The background of the conversation is as follows: "${prompts.openingSetup}" \n
    Here is all the knowledge and information about the user as a JSON object: \n ${JSON.stringify(knowledgeBase)} \n
    Here is the transcript of the conversation so far: \n ${chatHistoryString} \n
    Here are the writing types that the user has defined based on their writing: \n ${writingTypesSimple} \n
    Based on the user's latest message, do you have enough information to draft a written response to the request? If so, is there a writing type that this request falls into? Respond with a JSON object with the following structure { readyToRespond: true, writingType: "none" }. readyToRespond should be true or false based on if there's enough information to respond. writingType should include the name of the appropriate writing type or "none" if the request does not apply to any of the writing types.`;

    var openingIMconvo = [{"role": "user", "content": IMopeningSetup}];
    var openingResponse = await askGPTJSON(openingIMconvo);

    console.log('openingResponse from chatgpt', openingResponse);
    openingIMconvo.push({"role": "assistant", "content": openingResponse.message});

    var nextResponseText = "";
    var chosenWritingType = JSON.parse(openingResponse.message).writingType;

    // If Kai is not ready to respond, then Kai needs to respond and ask more questions
    if(openingResponse.readyToResponse == false) {
      nextResponseText = `Respond with a JSON object that includes three fields: { “commentary”: string-value, “written-output”: string-value, "writing-type": string-value }. The values should be strings.  Ask more questions before drafting a written output in the “commentary” value. All commentary should be directly addressed to the user by name and be concise and friendly. "written-output" value should be empty. "writing-type" should be empty.`
    } else {
      // If Kai is ready to respond, then we check for if it knows what wrting type to use
      if(chosenWritingType == "none") {
        nextResponseText = `Respond with a JSON object that includes two fields: { “commentary”: string-value, “written-output”: string-value, "writing-type": string-value }. The values should be strings. The "written-output" should include your drafted response. Any commentary about what you wrote should be included in the “commentary” value. All commentary should be directly addressed to the user by name and be concise and friendly. Do not include any exposition or commentary in the “written-output” value. Write the draft with markdown formatting. , "writing-type" should be "none".`
      } else {
        // If Kai knows what writing type to use, then it should ask for a response based on the writing type examples
        var writingTypeID = writingTypes.find(writingType => writingType.name == chosenWritingType).id;
        var writingTypeExamples = await getWritingTypeExamples(user.uid, writingTypeID);
        
        writingTypeExamples = JSON.stringify(writingTypeExamples);
        nextResponseText = `Respond with a JSON object that includes two fields: { “commentary”: string-value, “written-output”: string-value, "writing-type": string-value }. The values should be strings. The "written-output" should include your drafted response. Any commentary about what you wrote should be included in the “commentary” value. All commentary should be directly addressed to the user by name and be concise and friendly. Do not include any exposition or commentary in the “written-output” value. Write the draft with markdown formatting. , "writing-type" MUST be "${chosenWritingType}". \n
        Use the following examples to influence how you write your written response: \n ${writingTypeExamples}`;
      }
    }

    openingIMconvo.push({"role": "user", "content": nextResponseText});
    var nextResponse = await askGPTJSON(openingIMconvo);
    var finalResponse = nextResponse.message;

    finalResponse = { role: "assistant", content: finalResponse };

    // Add the final response to the DB
    addMessage(user.uid, writingProject.id, finalResponse);

    var newCoreMessages = [...messages, finalResponse];
    setMessages(newCoreMessages);
    setThinking(false);
    playSuccessChime(finalResponse.content)
  }

  // When a user submits a message, this function is called
  // Given a new user generated message, send it to GPT to get a response
  const handleSubmit = (text) => {
    var userResponse = {"role": "user", "content": text}
    var newCoreMessages = [...messages, userResponse];
    // Add the new message to the DB
    addMessage(user.uid, writingProject.id, userResponse);
    setMessages(newCoreMessages);
    setThinking(true);
  }

  // Toggles the display of the inner monologue div
  const toggleInnerMonologue = () => {
    if(showInnerMonologue == "none"){
      setShowInnerMonologue("block");
    } else {
      setShowInnerMonologue("none");
    }
  }

  const toggleUserWritingDocument = () => {
    setUserWritingDocument(!userWritingDocument);
  }

  // This function updates the completion status of the writing project
  // and is passed down to the DocumentArea component. The completion status is a boolean
  const updateWPcompletion = async (completionStatus) => {
    await updateWPcompletionStatus(user.uid, writingProject.id, completionStatus);
    props.refreshListOfWritingProjects();
    if(completionStatus == true) {
      console.log('** Writing project marked as complete & starting to add convesation to KB **');
      var knowledgeBase = await getKnowledgeBase(user.uid);
      updateKnowledgeGraphFromConversation(user.uid, messages, knowledgeBase);
    }
  }

  return (
    <div className='page'>
      { userWritingDocument == true && messages.length < 3 ? "" :
        <div className='chatContainer'>
          <div className='chatHeader' style={{ position: 'sticky', top: 0 }}>
            <WPTitle refreshListOfWritingProjects={props.refreshListOfWritingProjects} user={user} writingProject={writingProject} />
            {/* <button onClick={toggleUserWritingDocument} className='toggleIM'><FontAwesomeIcon icon="fa-toggle-on" /></button> */}
            <button onClick={toggleInnerMonologue} className='toggleIM'><FontAwesomeIcon icon="fa-brain" /></button>
          </div>
          <ChatConversation thinking={thinking} messages={messages} />
          <Textbox canEdit={!thinking} userWritingDocument={userWritingDocument} onSubmit={handleSubmit}></Textbox>
        </div>
      }
      <div className='chatContainer inner-mono' style={{display: showInnerMonologue}}>
          <div className='chatHeader' style={{ position: 'sticky', top: 0 }}>
          <h1 style={{ display: 'inline-block' }}>Inner Monologue { writingProject != null ? writingProject.id : "" }</h1>
          </div>
          <ChatConversation messages={innerMessages} />
      </div>
      { writingProject != null ?
        <DocumentArea
          user={user}
          writingProject={writingProject}
          messages={messages}
          refreshListOfWritingProjects={props.refreshListOfWritingProjects}
          userWritingDocument={userWritingDocument}
          toggleUserWritingDocument={toggleUserWritingDocument}
          toggleInnerMonologue={toggleInnerMonologue}
          updateWPcompletion={updateWPcompletion}
        /> : "" }
      
    </div>
  );
}

export default ChatPage;
