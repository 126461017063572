// import React, { useState, useEffect, useRef } from 'react';
// import { updateWPTitle } from './../functions/firebase';


// const WPTitle = (props) => {

//     const [title, setTitle] = useState('');

//     useEffect(() => {
//         if(props.writingProject != null) {
//             setTitle(props.writingProject.title);
//         }
//     }, [props.writingProject]);

//     const handleTitleChange = (event) => {
//         setTitle(event.target.value);
//     }

//     const saveNewTitle = async () => {
//         var completedUpdate = await updateWPTitle(props.user.uid, props.writingProject.id, title);
//         if(completedUpdate) {
//             props.refreshListOfWritingProjects();
//         }
//     }

//     return (
//         <textarea onChange={handleTitleChange} onBlur={saveNewTitle} className='wp-title' value={title} />
//     );
// };

// export default WPTitle;

import React, { useState, useEffect, useRef } from 'react';
import { updateWPTitle } from './../functions/firebase';

const WPTitle = (props) => {
  const [title, setTitle] = useState('');
  const textareaRef = useRef(null); // Create a ref to access the textarea element

  useEffect(() => {
    if (props.writingProject != null) {
      setTitle(props.writingProject.title);
    }
  }, [props.writingProject]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const saveNewTitle = async () => {
    var completedUpdate = await updateWPTitle(props.user.uid, props.writingProject.id, title);
    if (completedUpdate) {
      props.refreshListOfWritingProjects();
    }
    textareaRef.current.blur(); // Unfocus the textarea after saving
  };

  // Function to handle Enter key press
const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        saveNewTitle();
    }
  };

  return (
    <textarea
      ref={textareaRef} // Attach the ref to the textarea
      onChange={handleTitleChange}
      onKeyDown={handleEnterKey} // Add onKeyDown handler
      className='wp-title'
      value={title}
    />
  );
};

export default WPTitle;


