import { db } from './../functions/firebase';
import { doc, updateDoc, serverTimestamp, getDoc, setDoc } from "firebase/firestore";
import { askGPT, askGPTJSON, findDifferences, compressChatHistoryIntoString } from './../functions/coreFunctions';

// A message is sent to us
// We first need to ask Does this message contain meaningful information about the user, their product, company, or preference in communication? If yes, what is it?
// If the message does not contain meaningful information, then we need to do nothing
// If the message does contain meaningful information, then we need to say: Here is what is currently known about the user: [insert knowledge graph as a JSON object]. Is the information gathered from the previous message already integrated into their knowledge graph?
// If the information is already integrated, then we need to do nothing
// If the information is not already integrated, then we need to integrate it into the knowledge graph. We need to ask: Does the current structure of the knowledge graph have an area to cleanly insert the new information into, or does a new category need to be made for this information?
// If the current structure of the knowledge graph has an area to cleanly insert the new information into, then we need to insert the new information into the knowledge graph
// If the current structure of the knowledge graph does not have an area to cleanly insert the new information into, then we need to create a new category for this information and insert the new information into the knowledge graph

var currentKnowledgeBase = null;
var historyOfKnowledgeBase = [];

// Given a string for user ID and a knowledge base object as a JSON object, updates the user's knowledge base
const updateUserKnowledgeBase = async (userId, knowledgeBase) => {
    try {
        // Create a reference to the user's knowledge base collection
        const knowledgeBaseRef = doc(db, "users", userId);

        // Add a new knowledge base document in the knowledge base collection
        await updateDoc(knowledgeBaseRef, {
            knowledgeBase,
            lastModifiedKnowledgeBase: serverTimestamp() // Adds a timestamp using Firebase's server time
        });

        currentKnowledgeBase = knowledgeBase;
    } catch (e) {
        console.error("Error updating document: ", e);
    }
}

// Given a user ID, returns the user's knowledge base as a JSON object
const getKnowledgeBase = async (userId) => {
    // If we don't already have the knowledge base, then we need to get it from the DB
    if(currentKnowledgeBase == null){
        try {
            // Create a reference to the user's knowledge base collection
            const knowledgeBaseRef = doc(db, "users", userId);

            // Add a new knowledge base document in the knowledge base collection
            const knowledgeBase = await getDoc(knowledgeBaseRef);

            if (knowledgeBase.exists()) {
                // Calling data() brings all fields for the account. Must go into knowledgeBase key to get the knowledge base
                currentKnowledgeBase = knowledgeBase.data().knowledgeBase;
                historyOfKnowledgeBase = [currentKnowledgeBase];
                return currentKnowledgeBase;
            } else {
                // There is no data in the user's account so we need to seed it
                console.log('** New account: seeding knowledge base **')
                await setDoc(knowledgeBaseRef, {
                    knowledgeBase: initialKnowledgeBase,
                    lastModifiedKnowledgeBase: serverTimestamp() // Adds a timestamp using Firebase's server time
                });
                historyOfKnowledgeBase = [initialKnowledgeBase];
                return initialKnowledgeBase;
            }
            
        } catch (e) {
            console.error("Error getting document: ", e);
        }

    // If we already have the knowledge base, then we can just return it
    } else {
        return currentKnowledgeBase;
    }
}

// Given a user ID, a message, and a knowledge graph, analyzes the message and updates the knowledge graph
const analyzeAndUpdateKnowledgeGraph = async (userId, message, knowledgeGraph) => {
    let chatHistory = [];

    console.log('** analyzing messages and knowledge graph to see if it needs updating... **')

    // Step 1: Check if the message contains meaningful information
    chatHistory.push({ role: 'system', content: `Does this message contain meaningful information about the user, their product, company, or preference in communication? Respond with 'NO' if it doesn't; otherwise, explain what the meaningful information is.` });
    chatHistory.push({ role: 'user', content: message });

    let decision = await askGPT(chatHistory);
    if (decision.message.trim().toUpperCase() === 'NO') {
        console.log('** exiting because the message does not contain meaningful information')
        return null;
    }

    // Step 2: Check if the information is already integrated
    chatHistory.push({ role: 'system', content: `Given the current knowledge graph: ${JSON.stringify(knowledgeGraph)}. \n Is the information gathered from the previous message already integrated into the user's knowledge graph? Respond with 'NO' if it is not; otherwise, confirm with 'YES'.` });
    decision = await askGPT(chatHistory);
    if (decision.message.trim().toUpperCase() === 'YES') {
        console.log('** exiting because the information is already integrated')
        return null;
    }

    // Step 3: Ask GPT to rewrite the knowledge base with the new information
    let promptForRewriting = `Given the current knowledge graph and the new information in this message: ${message}, rewrite the knowledge graph as a JSON object incorporating the new information, considering if a new category is needed or not.`;
    chatHistory.push({ role: 'system', content: promptForRewriting });

    let updatedKnowledgeGraph = await askGPTJSON(chatHistory);
    
    // Assuming updatedKnowledgeGraph.message contains the JSON object
    try {
        let knowledgeBaseObject = JSON.parse(updatedKnowledgeGraph.message);
        await updateUserKnowledgeBase(userId, knowledgeBaseObject);
        currentKnowledgeBase = knowledgeBaseObject;
        console.log('** updated knowledge graph:')

        historyOfKnowledgeBase.push(currentKnowledgeBase);

        return getKnowledgeComparison();
    } catch (e) {
        console.error("Error parsing or updating the knowledge graph: ", e);
    }
}


// Given a user ID, a message, and a knowledge graph, analyzes the message and updates the knowledge graph
const updateKnowledgeGraphFromConversation = async (userId, conversation, knowledgeGraph) => {
    let chatHistory = [];

    console.log('** analyzing entire conversation and knowledge graph to see if it needs updating... **')

    // Ask GPT to rewrite the knowledge base with the conversation given
    chatHistory.push({ role: 'system', content: `Given the current knowledge graph: ${JSON.stringify(knowledgeGraph)}.` });
    let promptForRewriting = `Given the current knowledge graph, evaluate the following conversation and decide if new information exists: ${compressChatHistoryIntoString(conversation)}. Rewrite the knowledge graph as a JSON object incorporating the new information, considering if a new category is needed or not.`;
    chatHistory.push({ role: 'system', content: promptForRewriting });

    let updatedKnowledgeGraph = await askGPTJSON(chatHistory);
    
    // Assuming updatedKnowledgeGraph.message contains the JSON object
    try {
        let knowledgeBaseObject = JSON.parse(updatedKnowledgeGraph.message);
        await updateUserKnowledgeBase(userId, knowledgeBaseObject);
        currentKnowledgeBase = knowledgeBaseObject;
        console.log('** updated knowledge graph:')

        historyOfKnowledgeBase.push(currentKnowledgeBase);

        return getKnowledgeComparison();
    } catch (e) {
        console.error("Error parsing or updating the knowledge graph: ", e);
    }
}

const getKnowledgeComparison = () => {
    var previousKnowledgeBase = historyOfKnowledgeBase[historyOfKnowledgeBase.length - 2];
    var differences = findDifferences(previousKnowledgeBase, currentKnowledgeBase);
    differences.updatedKnowledgeBase = currentKnowledgeBase;
    return differences;
}


export { updateUserKnowledgeBase, getKnowledgeBase, analyzeAndUpdateKnowledgeGraph, updateKnowledgeGraphFromConversation}


  var initialKnowledgeBase = {
    aboutUser: {
        name: "",
        role: "",
        company: "",
    },
    aboutCompany: {
        name: "",
        industry: "",
        product: "",
        productDescription: "",
    },
    aboutProduct: {
        name: "",
        description: ""
    },
    aboutCoworkers: [
        {
            name: "",
            role: "",
            prefferedCommunicationStyle: ""
        }
    ]
  }

//   updateUserKnowledgeBase(user.uid,initialKnowledgeBase);