import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';

const WritingsPage = (props) => {

    const [listOfWritingProjects, setListOfWritingProjects] = useState(props.listOfWritingProjects); // Default tab

    console.log(listOfWritingProjects)

    return (
        <div className='page'>
            <h1>Writings Page</h1>
            <div className='list-of-WP'>
                {listOfWritingProjects.map((writingProject, index) => {
                    return (
                        <p key={index}>{writingProject.completionStatus == true ? 'Done' : 'In Progress' } {writingProject.title}</p>
                    )
                })}
            </div>
            <Markdown className="markdown-block">foobar</Markdown>
        </div>
    )
}

export default WritingsPage;