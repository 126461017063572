import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, getDoc, getDocs, addDoc, updateDoc, deleteDoc, serverTimestamp, query, orderBy, onSnapshot, writeBatch, limit } from "firebase/firestore";
import { getAuth, signOut, signInWithPopup, GoogleAuthProvider, signInAnonymously } from "firebase/auth";
import prompts from './../prompts';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCQB-gHnsqCPwP8id8Pfyhx79XJX7vUnp8",
    authDomain: "pm-ai-144f7.firebaseapp.com",
    projectId: "pm-ai-144f7",
    storageBucket: "pm-ai-144f7.appspot.com",
    messagingSenderId: "47747156624",
    appId: "1:47747156624:web:ce3a9b7c4b87472dbda615",
    measurementId: "G-T3HLSMKQEJ"
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const provider = new GoogleAuthProvider();
const auth = getAuth();

const signInWithGoogle = async () => {
    signInWithPopup(auth, provider).then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
    }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
    });
}

const anonSignIn = async () => {
    signInAnonymously(auth)
        .then(() => {
            // Signed in..
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
        });
}   

// Given a user ID, adds a message to the user's messages collection
const addMessage = async (userId, writingProjectId, messageBlock, ignoreUniversalSave) => {
    try {
        // Create a reference to the user's messages collection
        const messagesRef = collection(db, "users", userId, "allMessages");
        // Create a reference to the user's messages collection
        const messagesInWPRef = collection(db, "users", userId, "writingProjects", writingProjectId, "messages");

        await addDoc(messagesInWPRef, {
            messageBlock,
            timestamp: serverTimestamp() // Adds a timestamp using Firebase's server time
        });

        if(ignoreUniversalSave != true) {
            // Add a new message document in the messages collection
            await addDoc(messagesRef, {
                messageBlock,
                timestamp: serverTimestamp() // Adds a timestamp using Firebase's server time
            });
        }

        // console.log("Message written with ID: ", messageDocRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const addToDoItem = async (userId, toDoItemName) => {
    try {
        const toDoItemsRef = collection(db, "users", userId, "toDoItems");
        
        // Get current highest sortOrder
        const toDoItemsQuery = query(toDoItemsRef, orderBy('sortOrder', 'desc'), orderBy('timeAdded', 'desc'));
        const items = await getDocs(toDoItemsQuery);
        const highestOrder = items.empty ? 0 : items.docs[0].data().sortOrder || 0;

        // Add new item with next sortOrder
        const newToDoItemRef = await addDoc(toDoItemsRef, {
            toDoItemName,
            completed: false,
            archived: false,
            sortOrder: highestOrder + 1000, // Use increments of 1000 to allow for easy reordering
            timeAdded: serverTimestamp()
        });

        return newToDoItemRef.id;
    } catch (e) {
        console.error("Error adding to do item: ", e);
    }
}

const updateToDoItem = async (userId, toDoItemId, toDoItemName, completed) => {
    try {
        // Create a reference to the user's messages collection
        const toDoItemRef = doc(db, "users", userId, "toDoItems", toDoItemId);

        // Add a new message document in the messages collection
        await updateDoc(toDoItemRef, {
            toDoItemName,
            completed,
            lastUpdated: serverTimestamp() // Adds a timestamp using Firebase's server time
        });

        // console.log("Message written with ID: ", messageDocRef.id);
    } catch (e) {
        console.error("Error updating to do item: ", e);
    }
}

const deleteToDoItem = async (userId, toDoItemId) => {
    try {
        // Create a reference to the user's messages collection
        const toDoItemRef = doc(db, "users", userId, "toDoItems", toDoItemId);

        // Add a new message document in the messages collection
        await updateDoc(toDoItemRef, {
            archived: true,
            lastUpdated: serverTimestamp() // Adds a timestamp using Firebase's server time
        });

        // console.log("Message written with ID: ", messageDocRef.id);
    } catch (e) {
        console.error("Error deleting to do item: ", e);
    }
}

const migrateToDoItems = async (userId) => {
    try {
        const toDoItemsRef = collection(db, "users", userId, "toDoItems");
        // Get items ordered by timeAdded to maintain existing order
        const toDoItemsQuery = query(toDoItemsRef, orderBy('timeAdded', 'asc'));
        const items = await getDocs(toDoItemsQuery);
        
        let sortOrder = 1000;
        const batch = writeBatch(db);
        
        items.forEach((doc) => {
            if (!doc.data().sortOrder) {  // Only update items without sortOrder
                const todoRef = doc.ref;
                batch.update(todoRef, {
                    sortOrder: sortOrder
                });
                sortOrder += 1000;
            }
        });

        await batch.commit();
        console.log("Migration completed successfully");
    } catch (e) {
        console.error("Error during migration: ", e);
    }
}

const getToDoItems = async (userId) => {
    try {
        const toDoItemsRef = collection(db, "users", userId, "toDoItems");
        // First, check if we need migration
        const sampleQuery = query(toDoItemsRef, limit(1));
        const sampleDocs = await getDocs(sampleQuery);
        
        if (!sampleDocs.empty && !sampleDocs.docs[0].data().sortOrder) {
            // If we find an item without sortOrder, run migration
            await migrateToDoItems(userId);
        }

        // Now get all items, sorted by sortOrder or timeAdded as fallback
        const toDoItemsQuery = query(
            toDoItemsRef, 
            orderBy('sortOrder', 'asc'), 
            orderBy('timeAdded', 'asc')
        );
        
        const toDoItems = await getDocs(toDoItemsQuery);

        var listOfToDoItems = [];
        toDoItems.forEach((doc) => {
            var document = doc.data();
            document.id = doc.id;
            listOfToDoItems.push(document);
        });
        return listOfToDoItems;
    } catch (e) {
        console.error("Error getting todo items: ", e);
        return [];
    }
}

const getMessages = async (userId, writingProjectID) => {
    try {
        // Create a reference to the user's messages collection
        const messagesRef = collection(db, "users", userId, "writingProjects", writingProjectID, "messages");
        const messagesQuery = query(messagesRef, orderBy('timestamp', 'asc'));
        // Add a new message document in the messages collection
        const messages = await getDocs(messagesQuery);

        var listOfMessages = [];
        messages.forEach((doc) => {
            // var document = doc.data();
            // document.id = doc.id;
            listOfMessages.push(doc.data()['messageBlock']);
        });
        return listOfMessages;

        // console.log("Message written with ID: ", messageDocRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
} 

const getWritingProjects = async (userId) => {
    try {
        // Create a reference to the user's writing projects collection
        
        const writingProjectsRef = collection(db, "users", userId, "writingProjects");
        const writingProjectQuery = query(writingProjectsRef, orderBy('createdAt', 'desc'));

        // Add a new writing project in the messages collection
        const writingProjects = await getDocs(writingProjectQuery);

        var listOfWritingProjects = [];
        writingProjects.forEach((doc) => {
            var document = doc.data();
            document.id = doc.id;
            listOfWritingProjects.push(document);
        });

        return listOfWritingProjects;

        // console.log("Message written with ID: ", messageDocRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const logout = async () => {
    signOut(auth).then(() => {
        // Sign-out successful.
        return true;
    }).catch((error) => {
        // An error happened.
        throw new Error("Error logging out");
    });
}

const newWritingProject = async (userId) => {
    try {
        // Create a reference to the user's writing projects collection
        const writingProjectRef = collection(db, "users", userId, "writingProjects");

        // Add a new writing project in the messages collection
        const writingProject = await addDoc(writingProjectRef, {
            title: "Untitled",
            createdAt: serverTimestamp() // Adds a timestamp using Firebase's server time
        });

        const initialConversation = [
            {"role": "system", "content": prompts.openingSetup},
            {"role": "assistant", "content": prompts.openingMessage}
        ]

        for (const messageBlock of initialConversation) {
            await addMessage(userId, writingProject.id, messageBlock, true);
        }

        var newWPRef = await getDoc(writingProject);

        console.log(newWPRef.data())
        console.log(newWPRef.id)

        var newWP = newWPRef.data();
        newWP.id = newWPRef.id;
        return newWP;

        // console.log("Message written with ID: ", messageDocRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const getDocuments = async (userId, writingProjectId) => {
    try {
        // Create a reference to the user's writing projects collection
        const documentListRef = collection(db, "users", userId, "writingProjects", writingProjectId, "documents");
        const documentListQuery = query(documentListRef, orderBy('createdAt', 'desc'));

        // Add a new writing project in the messages collection
        const documents = await getDocs(documentListQuery);

        var listOfDocuments = [];
        documents.forEach((doc) => {
            var document = doc.data();
            document.id = doc.id;
            listOfDocuments.push(document);
        });

        return listOfDocuments;

        // console.log("Message written with ID: ", messageDocRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const saveDocument = async (userId, writingProjectId, documentContent) => {
    try {
        // Create a reference to the user's writing projects collection
        const writingProjectRef = collection(db, "users", userId, "writingProjects", writingProjectId, "documents");

        // Add a new writing project in the messages collection
        // await updateDoc(writingProjectRef, {
        //     documentContent,
        //     lastSavedTimestamp: serverTimestamp() // Adds a timestamp using Firebase's server time
        // });

        await addDoc(writingProjectRef, {
            content: documentContent,
            createdAt: serverTimestamp() // Adds a timestamp using Firebase's server time
        });

        // console.log("Message written with ID: ", messageDocRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const updateWPTitle = async (userId, writingProjectId, newTitle) => {
    try {
        // Create a reference to the user's writing projects collection
        const writingProjectRef = doc(db, "users", userId, "writingProjects", writingProjectId);

        // Add a new writing project in the messages collection
        await updateDoc(writingProjectRef, {
            title: newTitle,
            lastSavedTimestamp: serverTimestamp() // Adds a timestamp using Firebase's server time
        });

        return true;

        console.log("Updated writing project title");
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const updateWPcompletionStatus = async (userId, writingProjectId, completionStatus) => {
    try {
        // Create a reference to the user's writing projects collection
        const writingProjectRef = doc(db, "users", userId, "writingProjects", writingProjectId);

        // Add a new writing project in the messages collection
        await updateDoc(writingProjectRef, {
            completionStatus,
            lastSavedTimestamp: serverTimestamp() // Adds a timestamp using Firebase's server time
        });

        return true;

        console.log("Updated writing project title");
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const saveNote = async (userId, writingProjectId, noteContent) => {
    try {
        // Create a reference to the user's writing projects collection
        const writingProjectRef = doc(db, "users", userId, "writingProjects", writingProjectId);

        // Add a new writing project in the messages collection
        await updateDoc(writingProjectRef, {
            noteContent,
            noteLastSavedTimestamp: serverTimestamp() // Adds a timestamp using Firebase's server time
        });

        console.log("Updated writing project note");
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const getNote = async (userId, writingProjectId) => {
    try {
        // Create a reference to the user's writing projects collection
        const writingProjectRef = doc(db, "users", userId, "writingProjects", writingProjectId);

        // Add a new writing project in the messages collection
        const writingProject = await getDoc(writingProjectRef);

        return writingProject.data().noteContent;
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const addNewWritingType = async (userId, name, description) => {
    try {
        // Create a reference to the user's writing projects collection
        const writingTypeRef = collection(db, "users", userId, "writingTypes");

        // Add a new writing project in the messages collection
        const docRef = await addDoc(writingTypeRef, {
            name,
            description,
            createdAt: serverTimestamp() // Adds a timestamp using Firebase's server time
        });

        console.log("Added new writing type");
        return docRef.id;

    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const getWritingTypes = async (userId) => {
    try {
        // Create a reference to the user's writing projects collection
        const writingTypeRef = collection(db, "users", userId, "writingTypes");
        const writingTypeQuery = query(writingTypeRef, orderBy('createdAt', 'desc'));

        // Add a new writing project in the messages collection
        const writingTypes = await getDocs(writingTypeQuery);

        var listOfWritingTypes = [];
        writingTypes.forEach((doc) => {
            var document = doc.data();
            document.id = doc.id;
            listOfWritingTypes.push(document);
        });

        return listOfWritingTypes;

        console.log("Got writing types");
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const deleteWritingType = async (userId, writingTypeId) => {
    try {
        // Create a reference to the user's writing projects collection
        const writingTypeRef = doc(db, "users", userId, "writingTypes", writingTypeId);

        // Add a new writing project in the messages collection
        await deleteDoc(writingTypeRef);

        console.log("Deleted writing type");
        return true;
    } catch (e) {
        console.error("Error deleting document: ", e);
    }

}

const addWritingTypeExample = async (userId, writingTypeId, exampleContent, exampleContext = null) => {
    try {
        // Create a reference to the examples subcollection within the specified writing type document
        const examplesCollectionRef = collection(db, "users", userId, "writingTypes", writingTypeId, "examples");

        // Dynamically construct the update object
        let updateData = {
            content: exampleContent,
            lastSavedTimestamp: serverTimestamp() // Adds a timestamp using Firebase's server time
        };

        // Conditionally add exampleContext if it was provided
        if (exampleContext !== null) {
            updateData.context = exampleContext;
        }

        // Add a new example document to the examples subcollection
        await addDoc(examplesCollectionRef, updateData);

        console.log("Added new writing type example");
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const getWritingTypeExamples = async (userId, writingTypeId) => {
    try {
        // Create a reference to the examples subcollection within the specified writing type document
        const examplesCollectionRef = collection(db, "users", userId, "writingTypes", writingTypeId, "examples");
        const examplesQuery = query(examplesCollectionRef, orderBy('lastSavedTimestamp', 'desc'));

        // Add a new writing project in the messages collection
        const examples = await getDocs(examplesQuery);

        var listOfExamples = [];
        examples.forEach((doc) => {
            var document = doc.data();
            document.id = doc.id;
            listOfExamples.push(document);
        });

        return listOfExamples;

        console.log("Got writing type examples");
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

const deleteWritingExample = async (userId, writingTypeId, exampleId) => {
    try {
        // Create a reference to the examples subcollection within the specified writing type document
        const exampleRef = doc(db, "users", userId, "writingTypes", writingTypeId, "examples", exampleId);

        // Delete the example
        await deleteDoc(exampleRef);

        console.log("Deleted writing type example");
    } catch (e) {
        console.error("Error deleting document: ", e);
    }
}

const updateToDoItemOrder = async (userId, todoId, newSortOrder) => {
    try {
        const todoRef = doc(db, "users", userId, "toDoItems", todoId);
        await updateDoc(todoRef, {
            sortOrder: newSortOrder,
            lastUpdated: serverTimestamp()
        });
    } catch (e) {
        console.error("Error updating todo item order: ", e);
    }
}

export {
    db,
    collection,
    app,
    analytics, 
    auth,
    signInWithGoogle,
    anonSignIn,
    getDocuments,
    getMessages,
    addMessage,
    newWritingProject,
    saveDocument,
    getWritingProjects,
    onSnapshot,
    updateWPTitle,
    updateWPcompletionStatus,
    logout,
    addToDoItem,
    getToDoItems,
    updateToDoItem,
    deleteToDoItem,
    saveNote,
    getNote,
    addNewWritingType,
    getWritingTypes,
    deleteWritingType,
    addWritingTypeExample,
    getWritingTypeExamples,
    deleteWritingExample,
    updateToDoItemOrder
};

