import React, { useState, useEffect, useRef } from 'react';
import MessageBubble from './messagebubble'

const ChatConversation = (props) => {

    const chatHistoryRef = useRef(null);

    const messages  = props.messages;
    const thinking = props.thinking;

    useEffect(() => {
        chatHistoryRef.current.scrollTo({
          top: chatHistoryRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, [messages]);

    return (
        <div className="chat-log" ref={chatHistoryRef}>
            {messages && messages.map((message, index) => (
              // If the message is a system message, don't display it
              message.role=="system" ? "" : <MessageBubble key={index} messageID={index} content={message.content} role={message.role} />
              // <MessageBubble key={index} messageID={index} content={message.content} role={message.role} />
            ))}
            {thinking ? <MessageBubble key={messages.length} messageID={messages.length} content="$$SHOWLOADINGICON$$" role="assistant" /> : ""}
      </div>
    )
};

export default ChatConversation;
