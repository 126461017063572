import React, { useState, useEffect, useRef } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMicrophone, faCircleStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Textbox(props) {

  library.add(faMicrophone, faCircleStop);

  const [inputValue, setInputValue] = useState('');
  const [canEdit, setCanEdit] = useState(true);
  const [userWritingDocument, setUserWritingDocument] = useState(props.userWritingDocument);
  
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  // This is needed to create a real time reference to the userWritingDocument
  const userWritingDocumentRef = useRef(userWritingDocument);
  
  // This is needed to update the userWritingDocumentRef when the userWritingDocument changes
  useEffect(() => {
    userWritingDocumentRef.current = userWritingDocument;
  }, [userWritingDocument]);

  useEffect(() => {
      // Attach the event listener
      window.addEventListener('keydown', shortcutListener);

      // Detach the event listener on cleanup
      return () => {
          window.removeEventListener('keydown', shortcutListener);
      };
  }, [listening, transcript, resetTranscript]);

  useEffect(() => {
    setCanEdit(props.canEdit);
  }, [props.canEdit]);

  useEffect(() => {
    if(listening){
      stopListening();
    }
    setUserWritingDocument(props.userWritingDocument);
  }, [props.userWritingDocument]);

  const shortcutListener = (event) => {
    // Check if the event is from an input field or similar
    console.log('triggered', userWritingDocument)
    if (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA" || canEdit === false || userWritingDocumentRef.current === true) {
        return;
    }

    // Check if 'space+D' was pressed, turn on or off the microphone
    if (event.code === "KeyD") {
      event.preventDefault(); // Prevent the default space key action (e.g., page scrolling)
      if(listening) {
        SpeechRecognition.stopListening();
        setInputValue(`${inputValue} ${transcript}`);
        resetTranscript();
      } else {
        startListening();
      }
      
    } else if(event.key === 'Enter'){
      // If a user clicks enter while the recording is happening
      // then stop the transcript and send what's already been said
      if(listening) {
        SpeechRecognition.stopListening();
        props.onSubmit(`${inputValue} ${transcript}`);
        setInputValue(``);
        resetTranscript();
      }
    }
  };
  
  // checks if the browser supports speech recognition
  if (!browserSupportsSpeechRecognition) {
      return <span>Browser doesn't support speech recognition.</span>;
  }

  const startListening = () => {
      SpeechRecognition.startListening({continuous: true});
  }

  const stopListening = () => {
      SpeechRecognition.stopListening();
      setInputValue(`${inputValue} ${transcript}`);
      resetTranscript();
  }

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        props.onSubmit(inputValue);
        setInputValue('');
        resetTranscript();
      }
    };
  
    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const clickMicButton = () => {
      if(canEdit){
        if (listening) {
          stopListening();
        } else {
          startListening();
        }
      }
    }

    return (
      <div className='textbox-container'>
        { listening ?
          <div className={canEdit ? 'textbox' : 'textbox noEdit'}>
            <p>{inputValue} {transcript}</p>
          </div>
          :
          <textarea
            className={canEdit ? 'textbox' : 'textbox noEdit'}
            type="text"
            value={inputValue}
            readOnly={!canEdit}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        } 
        <button className={`mic-btn ${listening ? 'listening' : 'not-listening'} ${canEdit ? '' : 'noEdit'}`} onClick={clickMicButton}>
          {listening ? <FontAwesomeIcon icon="fa-solid fa-circle-stop"/> : <FontAwesomeIcon icon="fa-solid fa-microphone" />}
        </button>
      </div>
        
    );
}

export default Textbox;

