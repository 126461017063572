import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faFaceLaughBeam, faSquarePen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const MessageBubble = (props) => {

  library.add(faFaceLaughBeam, faSquarePen);

  // Capitalize the first letter of the role
  var displayRole = props.role.charAt(0).toUpperCase() + props.role.slice(1);

  // Change the display name of the role
  if(displayRole == "Assistant") {
    displayRole = "Kai";
  } else if(displayRole == "User") {
    displayRole = "Justin"
  }

  var content = props.content;
  var writtenOutput = "";
  var notes = "";
  var writingType = undefined

  if(props.role == "assistant" && props.content != '$$SHOWLOADINGICON$$') {
    try {
      content = JSON.parse(props.content)
      if(content['writing-type'] != undefined) {
        writingType = content['writing-type'];
      }
      writtenOutput = content['written-output'];
      notes = content['commentary'];
      notes = notes.replace(/^\s+|\s+$/g, '');
    // If the content is not JSON, just use the content as is
    } catch(event) {
      notes = content
    }
  } else {
    notes = content;
  }

  return (
    <div className={`message-wrapper ${props.role}`}>
      <span className='role-label'>{displayRole} {(content=="$$SHOWLOADINGICON$$") ? <FontAwesomeIcon icon="fa-face-laugh-beam" spin /> : ""}</span>
      { (content=="$$SHOWLOADINGICON$$") ? "" :
        <div className='message-bubble'>
          {/* {writtenOutput != "" ? <div className='toggle-writing-message'><FontAwesomeIcon icon="fa-square-pen" /> Show Writing</div> : ""}  */}
          {/* {writingType != undefined ? <div className='writing-type-bubble'>Writing Type: {writingType}</div> : ""} */}
          <Markdown>{notes}</Markdown>
        </div>
      }
    </div>
  );
};

MessageBubble.propTypes = {
  role: PropTypes.oneOf(['user', 'assistant','system']).isRequired,
  content: PropTypes.string.isRequired,
};

export default MessageBubble;
