import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { addNewWritingType, getWritingTypes, deleteWritingType, addWritingTypeExample, getWritingTypeExamples, deleteWritingExample } from '../functions/firebase';

const ExamplesPage = (props) => {

    const [activeWritingType, setActiveWritingType] = useState(null);
    const [newWritingTypeName, setNewWritingTypeName] = useState('');
    const [newWritingTypeDescription, setNewWritingTypeDescription] = useState('');
    const [writingTypes, setWritingTypes] = useState([]); // State to store writing types
    const [newExampleContent, setNewExampleContent] = useState('');
    const [newExampleContext, setNewExampleContext] = useState('');
    const [examples, setExamples] = useState([]);

    useEffect(() => {
        fetchWritingTypes();
    }, []);

    useEffect(() => {
        if (activeWritingType) {
            fetchExamples();
        }
    }, [activeWritingType]);

    const fetchWritingTypes = async () => {
        const types = await getWritingTypes(props.user.uid);
        setWritingTypes(types);
        console.log(types);
    };

    // Updates the active writing type
    const changeActiveWritingType = (writingTypeID) => {
        const writingType = writingTypes.find(type => type.id === writingTypeID);
        setActiveWritingType(writingType);
    }

    // Updates the state for the new writing type name
    const handleNewWritingTypeNameChange = (event) => {
        setNewWritingTypeName(event.target.value);
    }

    // Updates the state for the new writing type description
    const handleNewWritingTypeDescriptionChange = (event) => {
        setNewWritingTypeDescription(event.target.value);
    }

    // Handles the form submission for creating a new writing type
    const handleCreateNewWritingType = async (event) => {
        event.preventDefault(); // Prevents the form from causing a page reload
        const newWritingTypeID = await addNewWritingType(props.user.uid, newWritingTypeName, newWritingTypeDescription);
        changeActiveWritingType(newWritingTypeID); // Changes the active writing type to the newly created one
        fetchWritingTypes(); // You might need to adjust fetchWritingTypes to not use useEffect or make a new function for this purpose

        setNewWritingTypeName(''); // Resets the input field
        setNewWritingTypeDescription(''); // Resets the input field
    }

    const deleteWT = async () => {
        await deleteWritingType(props.user.uid, activeWritingType.id);
        fetchWritingTypes();
        setActiveWritingType(null);
    }

    const goBackToTypeList = () => {
        setActiveWritingType(null);
    }

    const fetchExamples = async () => {
        const examples = await getWritingTypeExamples(props.user.uid, activeWritingType.id);
        setExamples(examples);
    };

    const handleNewExampleContentChange = (event) => {
        setNewExampleContent(event.target.value);
    };

    const handleNewExampleContextChange = (event) => {
        setNewExampleContext(event.target.value);
    };

    const handleAddNewExample = async (event) => {
        event.preventDefault(); // Prevents the form from causing a page reload
        await addWritingTypeExample(props.user.uid, activeWritingType.id, newExampleContent, newExampleContext);
        setNewExampleContent('');
        setNewExampleContext('');
        fetchExamples(); // Refresh the examples list
    };

    const handleDeleteExample = async (exampleId) => {
        try {
            await deleteWritingExample(props.user.uid, activeWritingType.id, exampleId);
            // Filter out the deleted example from the examples state
            const updatedExamples = examples.filter(example => example.id !== exampleId);
            setExamples(updatedExamples);
        } catch (error) {
            console.error("Error deleting example:", error);
        }
    };

    // const [listOfWritingProjects, setListOfWritingProjects] = useState(props.listOfWritingProjects); // Default tab
    return (
        <section className='light-gray-bg'>
            <div className='output-area'>
                <h1 className='breadcrumbs'><span className={`${ activeWritingType ? 'clickable' : ''}`} onClick={goBackToTypeList}>Writing Type Examples</span> { activeWritingType ? <><span className='subtle-gray'><FontAwesomeIcon icon="fa-solid fa-chevron-right" /></span> {activeWritingType.name}</> : <></> }</h1>
                { activeWritingType == null ?
                    <div className='list-of-example-types'>
                        {writingTypes.map((type, index) => (
                            <div key={index} className='example-type-block'>
                                <h2>{type.name}</h2>
                                <p>{type.description}</p>
                                <button className='secondary-btn' onClick={() => changeActiveWritingType(type.id)}>View</button>
                            </div>
                        ))}
                        <div className='new-writing-button'>
                            <h3>Create New Writing Type +</h3>
                            <form onSubmit={handleCreateNewWritingType}>
                                <p>Writing Type Name</p>
                                <input
                                    type='text'
                                    value={newWritingTypeName}
                                    onChange={handleNewWritingTypeNameChange}
                                ></input>
                                <p>Description</p>
                                <input
                                    type='text'
                                    value={newWritingTypeDescription}
                                    onChange={handleNewWritingTypeDescriptionChange}
                                ></input>
                                <button className='secondary-btn' type="submit">Create</button>
                            </form>
                        </div>
                    </div>
                    :
                    <div className='example-type-page'>
                        <div className='flex-col example-form'>
                            <h2>{activeWritingType.name}</h2>
                            <p>{activeWritingType.description}</p>
                            <form onSubmit={handleAddNewExample} className='new-example-form'>
                                <h3>Add New Example</h3>
                                <p>Content *</p>
                                <textarea className='textbox' value={newExampleContent} onChange={handleNewExampleContentChange}></textarea>
                                <p>Context (optional)</p>
                                <textarea className='textbox' value={newExampleContext} onChange={handleNewExampleContextChange}></textarea>
                                <button className='primary-btn' type="submit">Add Example</button>
                            </form>
                            <div className='delete'>
                                <button className='secondary-btn' onClick={deleteWT}><FontAwesomeIcon icon="fa-solid fa-trash-can" /> Delete Writing Type</button>
                            </div>
                        </div>
                        <div>
                            <h3>All Examples - {examples.length}</h3>
                            { examples.length > 0 ?
                                <div className='example-list'>
                                {examples.map((example, index) => (
                                    <div key={index} className='example'>
                                        <p className='gray'>Example Content</p>
                                        <p>{example.content}</p>
                                        <p className='gray'>Example Context</p>
                                        <p>{example.context || 'N/A'}</p>
                                        <button className='copy-btn' onClick={() => handleDeleteExample(example.id)}><FontAwesomeIcon icon="fa-solid fa-trash-can" /></button>
                                    </div>
                                ))}
                                </div>
                            : <div className='example'><p>No examples added</p></div> }
                        </div>
                    </div>
                }
            </div>
        </section>
    )
}

export default ExamplesPage;