import React, { useEffect, useState } from 'react';
import { getToDoItems, addToDoItem, updateToDoItem, deleteToDoItem, updateToDoItemOrder} from './../functions/firebase';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NestedListComponent from './../components/draggableList';
import { debounce } from 'lodash';

const ToDoPage = (props) => {
    library.add(faBoxArchive);

    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState('');
    const [editIndex, setEditIndex] = useState(-1);
    const [editText, setEditText] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [activeItemId, setActiveItemId] = useState(null);
    const [pendingOrderUpdates, setPendingOrderUpdates] = useState(new Map());

    useEffect(() => {
        const getToDos = async () => {
            const toDoItems = await getToDoItems(props.user.uid);
            setTodos(toDoItems);
        };

        getToDos();
    }, []);

    const handleAddTodo = async () => {
        if (newTodo.trim() !== '') {
            const maxSortOrder = todos.reduce((max, todo) => 
                !todo.archived && todo.sortOrder > max ? todo.sortOrder : max, 0);
            
            const newToDoItemID = await addToDoItem(props.user.uid, newTodo);
            setTodos([...todos, { 
                toDoItemName: newTodo, 
                completed: false, 
                id: newToDoItemID, 
                archived: false,
                sortOrder: maxSortOrder + 1000  // Give some space between numbers for future insertions
            }]);
            setNewTodo('');
        }
    };

    const handleToggleTodo = async (index, todoID, todoName, currentCompleted) => {
        const updatedTodos = [...todos];
        updatedTodos[index].completed = !updatedTodos[index].completed;
        setTodos(updatedTodos);
        await updateToDoItem(props.user.uid, todoID, todoName, !currentCompleted);
    };

    const handleDeleteTodo = async (index, todoID) => {
        const updatedTodos = [...todos];
        updatedTodos[index].archived = true;
        setTodos(updatedTodos);
        await deleteToDoItem(props.user.uid, todoID);
    };

    const handleEditTodo = (index, isCompleted) => {
        if(!isCompleted){
            setEditIndex(index);
            setEditText(todos[index].toDoItemName);
        }
    };

    const handleSaveTodo = async (toDoID) => {
        console.log(editText)
        await updateToDoItem(props.user.uid, toDoID, editText, false);
        const updatedTodos = [...todos];
        updatedTodos[editIndex].toDoItemName = editText;
        setTodos(updatedTodos);
        setEditIndex(-1);
        setEditText('');
    };

    const handleCancelEdit = () => {
        setEditIndex(-1);
        setEditText('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (editIndex !== -1) {
                handleSaveTodo();
            } else {
                handleAddTodo();
            }
        }
    };

    const handleShowArchived = () => {
        setShowArchived(!showArchived);
    }

    const syncOrderWithBackend = debounce(async (updates) => {
        for (const [todoId, newOrder] of updates) {
            await updateToDoItemOrder(props.user.uid, todoId, newOrder);
            console.log('Synced order with backend');
        }
        setPendingOrderUpdates(new Map());
    }, 7000);

    const handleMoveItem = (index, direction) => {
        const unarchivedTodos = todos.filter(t => !t.archived);
        const unarchivedIndex = unarchivedTodos.findIndex(t => t.id === todos[index].id);
        
        if ((direction === 'up' && unarchivedIndex === 0) || 
            (direction === 'down' && unarchivedIndex === unarchivedTodos.length - 1)) {
            return;
        }

        const updatedTodos = [...todos];
        const currentItem = updatedTodos[index];
        
        let adjacentIndex = index;
        do {
            adjacentIndex += (direction === 'up' ? -1 : 1);
        } while (adjacentIndex >= 0 && adjacentIndex < updatedTodos.length && updatedTodos[adjacentIndex].archived);
        
        const adjacentItem = updatedTodos[adjacentIndex];

        if (!currentItem.archived && !adjacentItem.archived) {
            const tempOrder = currentItem.sortOrder;
            currentItem.sortOrder = adjacentItem.sortOrder;
            adjacentItem.sortOrder = tempOrder;

            const newUpdates = new Map(pendingOrderUpdates);
            newUpdates.set(currentItem.id, currentItem.sortOrder);
            newUpdates.set(adjacentItem.id, adjacentItem.sortOrder);
            setPendingOrderUpdates(newUpdates);

            updatedTodos.sort((a, b) => a.sortOrder - b.sortOrder);
            setTodos(updatedTodos);

            syncOrderWithBackend(newUpdates);
        }
    };

    useEffect(() => {
        return () => {
            syncOrderWithBackend.cancel();
        };
    }, []);

    const handleKeyDown = (e, index) => {
        if (e.altKey) {
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                console.log('Moving item up');
                handleMoveItem(index, 'up');
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                console.log('Moving item down');
                handleMoveItem(index, 'down');
            }
        }
    };

    const handleItemFocus = (todoId) => {
        setActiveItemId(todoId);
    };

    return (
        <div className='page noFlex L-P-M toDoPage'>
            <h1>To Do</h1>
    
            <div className='toDoList'>
                {todos
                    .filter(todo => !todo.archived)
                    .map((todo, visibleIndex) => (
                        <div 
                            className={`toDoItem ${activeItemId === todo.id ? 'active-todo' : ''}`}
                            key={todo.id}
                            tabIndex={0}
                            onClick={() => handleItemFocus(todo.id)}
                            onKeyDown={(e) => handleKeyDown(e, todos.indexOf(todo))}
                            onBlur={() => setActiveItemId(null)}
                        >
                            {editIndex === todos.indexOf(todo) ? (
                                <>
                                    <span className="todo-number">{visibleIndex + 1}</span>
                                    <input
                                        type="checkbox"
                                        checked={todo.completed}
                                        disabled
                                    />
                                    <input
                                        type="text"
                                        value={editText}
                                        onChange={(e) => setEditText(e.target.value)}
                                    />
                                    <button className='minor-btn' onClick={() => handleSaveTodo(todo.id)}>Save</button>
                                    <button className='minor-btn' onClick={handleCancelEdit}>Cancel</button>
                                </>
                            ) : (
                                <>
                                    <span className="todo-number">{visibleIndex + 1}</span>
                                    <input
                                        type="checkbox"
                                        checked={todo.completed}
                                        onChange={() => handleToggleTodo(todos.indexOf(todo), todo.id, todo.toDoItemName, todo.completed)}
                                    />
                                    <span onClick={() => handleEditTodo(todos.indexOf(todo), todo.completed)}>
                                        {todo.toDoItemName}
                                    </span>
                                    {todo.completed ? <button className='minor-btn' onClick={() => handleDeleteTodo(todos.indexOf(todo), todo.id)}><FontAwesomeIcon icon="fa-box-archive" /></button> : ""}
                                </>
                            )}
                        </div>
                    ))}
            </div>
            <span className="todo-number"> </span>
            <input
                type="checkbox"
            />
            <input
                type="text"
                value={newTodo}
                onChange={(e) => setNewTodo(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Add new item"
            />
            <h4><button className='minor-btn' onClick={handleShowArchived}>{showArchived ? "Hide Archive": "Show Archived"}</button></h4>
            { showArchived ? 
            <div className='toDoList'>
                {todos
                    .filter(todo => todo.archived)
                    .map((todo, archivedIndex) => (
                        <div className='toDoItem' key={todo.id}>
                            <span className="todo-number">{archivedIndex + 1}</span>
                            <input
                                type="checkbox"
                                checked={todo.completed}
                            />
                            <span>
                                {todo.toDoItemName}
                            </span>
                        </div>
                    ))}
            </div>
            : ""}
            {/* <button onClick={handleAddTodo}>Add</button> */}
            {/* <NestedListComponent /> */}
        </div>
    );
};

export default ToDoPage;

