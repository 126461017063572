import React, { useState, useRef, useCallback, useEffect } from 'react';
import {Editor, EditorState, getDefaultKeyBinding, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './../RichEditor.css';

import { getNote, saveNote } from './../functions/firebase';

const InputDocument = (props) => {
    // const contentFromData = convertFromRaw(defaultContent);
    // const newEditorState = EditorState.createWithContent(contentFromData);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const editor = useRef(null);
    const debounceTimeout = useRef(null);
    const [writingProject, setWritingProject] = useState(props.writingProject);

    useEffect(() => {

        const loadNote = async () => {
            console.log('****** loading note...');
            const note = await getNote(props.user.uid, props.writingProject.id);
            if(note != null) {
                const contentFromData = convertFromRaw(note);
                const newEditorState = EditorState.createWithContent(contentFromData);
                setEditorState(newEditorState);
            } else if(note == null) {
                const newEditorState = EditorState.createEmpty();
                setEditorState(newEditorState);
            }
        }

        const newEditorState = EditorState.createEmpty();
        setEditorState(newEditorState);

        if(props.writingProject != null) {
            
            loadNote();
        }
    }, [props.writingProject]);

    useEffect(() => {
        setWritingProject(props.writingProject);
    }, [props.writingProject]);

    useEffect(() => {
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
          const content = convertToRaw(editorState.getCurrentContent());
          // Call your saveNote function with the current content
          // if the content isn't blank.
          if(content.blocks[0].text != "") {
            console.log('saving content...', content)
            saveNote(props.user.uid, writingProject.id, content); 
          }
        }, 1500); // 1000 milliseconds = 1 second debounce time
    
        return () => {
          clearTimeout(debounceTimeout.current);
        };
    }, [editorState]); // This effect runs when editorState changes
    
  
    const focusEditor = () => {
      if (editor.current) {
        editor.current.focus();
      }
    };
  
    const handleKeyCommand = useCallback((command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return true;
      }
      return false;
    }, []);
  
    const mapKeyToEditorCommand = useCallback((e) => {
      if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
          e,
          editorState,
          4, /* maxDepth */
        );
        if (newEditorState !== editorState) {
          setEditorState(newEditorState);
        }
      } else {
        return getDefaultKeyBinding(e);
      }
    }, [editorState]);
  
    const toggleBlockType = useCallback((blockType) => {
      setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    }, [editorState]);
  
    const toggleInlineStyle = useCallback((inlineStyle) => {
      setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    }, [editorState]);
  
    let className = 'RichEditor-editor';

    const contentState = editorState.getCurrentContent();

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    const logState = () => {
        const content = convertToRaw(editorState.getCurrentContent());
        console.log(content);
    };
  
    return (
      <div className='markdown-block'>
        <div className="RichEditor-root">
            <BlockStyleControls
            editorState={editorState}
            onToggle={toggleBlockType}
            />
            <InlineStyleControls
            editorState={editorState}
            onToggle={toggleInlineStyle}
            />
            <div className={className} onClick={focusEditor}>
            <Editor
                blockStyleFn={getBlockStyle}
                customStyleMap={styleMap}
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                keyBindingFn={mapKeyToEditorCommand}
                onChange={setEditorState}
                placeholder="Start writing..."
                ref={editor}
                spellCheck={true}
            />
            </div>
        </div>
      </div>
    );
  }

  // Custom overrides for "code" style.
  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
  }

  class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }

    render() {
      let className = 'RichEditor-styleButton';
      if (this.props.active) {
        className += ' RichEditor-activeButton';
      }

      return (
        <span className={className} onMouseDown={this.onToggle}>
          {this.props.label}
        </span>
      );
    }
  }

  const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'Quote', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'Code Block', style: 'code-block'},
  ];

  const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type) =>
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };

  var INLINE_STYLES = [
    {label: 'B', style: 'BOLD'},
    {label: 'I', style: 'ITALIC'},
    {label: 'U', style: 'UNDERLINE'},
  ];

  const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) =>
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };

//   const InputDocument = () => {
//     const [content, setContent] = useState('');
//     const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  
//     const handleChange = (event) => {
//       setContent(event.target.value);
//     };
  
//     return (
//       <div className='markdown-block'>
//         <RichEditorExample />
//       </div>
//     );
//   };

export default InputDocument;
