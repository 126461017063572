import React, { useState, useEffect } from 'react';
import Core from './core';
import { auth, signInWithGoogle, anonSignIn } from './functions/firebase';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged(newUser => {
      setUser(newUser);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <div>
      {user ? (
        <div>
          <Core user={user} /> {/* Render this if the user is signed in */}
        </div>
      ) : (
        <div>
          {/* Render this if no user is signed in */}
          <h1>Kai</h1>
          Please sign in to access the core features.<br/>
          <button onClick={signInWithGoogle}>Sign in with Google</button>
          <button onClick={anonSignIn}>Test Account</button>
        </div>
      )}
    </div>
  );
}

export default App;
